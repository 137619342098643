import React, { useState, useEffect } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Flex } from "antd";

import MainMenu from "./MainMenu";
import BaseLayoutStore from "../BaseLayoutStore";
import UserInfo from "../../../components/UserInfo";
import ViewGeneratorSearch from "../../../ViewGenerator/components/ViewGeneratorSearch";
import useDynamicSections from "../hooks/useDynamicSections";
import WrapperLayoutHeader from "./WrapperLayoutHeader";
import useCurrentRoute from "../hooks/useCurrentRoute";
import "../../../assets/scss/main.scss";

const AppHeader = ({ urlParsed, pageTitle, user, urlQuery, toggleShowSearchDrawer, t }) => {
  const [dynamicComponentsExist, setDynamicComponentsExist] = useState(false);
  const appConfig = JSON.parse(localStorage.getItem("appConfig"));

  const history = useHistory();
  const match = useRouteMatch();
  const { path } = match;

  const appHeaderSections = useDynamicSections(path, "appHeader");
  const appHeaderSections2 = useDynamicSections(path, "appHeader-2");
  const currentRoute = useCurrentRoute(path);

  useEffect(() => {
    if (currentRoute?.handle?.dynamicComponents) {
      setDynamicComponentsExist(true);
    }
  }, [currentRoute]);

  return (
    <WrapperLayoutHeader>
      <>
        <Flex justify="space-between" align="center">
          <Link to={"/mypriorities"}>
            <img
              className="logo"
              src={appConfig.header.path}
              onError={e => (e.target.src = "Beawre_logo_header.png")}
              alt="Beawre"
            />
          </Link>
          <MainMenu />
        </Flex>
        <Flex justify="space-between" align="center">
          {urlParsed &&
            dynamicComponentsExist &&
            appHeaderSections.map((Component, index) => {
              return (
                <Component
                  key={index}
                  urlQuery={urlQuery}
                  history={history}
                  page={path.substring(1, path.length)}
                  variant="basic"
                />
              );
            })}

          {urlParsed &&
            dynamicComponentsExist &&
            appHeaderSections2.map((Component, index) => (
              <Component key={index} toggleShowSearchDrawer={toggleShowSearchDrawer} />
            ))}

          {/* UI HUB START */}
          {BaseLayoutStore.forceShowSearch && (
            <ViewGeneratorSearch
              urlQuery={urlQuery}
              history={history}
              page={path.substring(1, path.length)}
              variant="basic"
            />
          )}
          <UserInfo pageTitle={pageTitle} t={t} history={history} />
        </Flex>
      </>
    </WrapperLayoutHeader>
  );
};
export default withTranslation()(observer(AppHeader));
