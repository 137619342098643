import { CaretDownOutlined, CaretUpOutlined, RightOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { themeConfig } from "../Common/Layout/config";
import UserStore from "../Common/Users/UserStore";

const ProjectsMenu = ({ t }) => {
  const [visibleProjectMenu, setVisibleProjectMenu] = useState(false);
  const history = useHistory();
  const { currentTheme } = themeConfig();

  useEffect(() => {
    UserStore.loadUserAccess().then(() => {
      UserStore.loadProjects();
    });
  }, []);

  if (!UserStore.projects) return <></>;

  const items = [
    {
      label: (
        <>
          <b>{t("userInfo.projects")}</b>
        </>
      ),
      key: "header_label"
    },
    {
      type: "divider"
    },
    ...UserStore.projects
      .filter(x => x.projects)
      .map(tenant => {
        return {
          type: UserStore.projects.length > 4 ? undefined : "group",
          label: tenant.value,
          icon: UserStore.projects.length > 4 ? <RightOutlined /> : undefined,
          key: `tenant_${tenant.id}`,
          children: tenant.projects.map(project => {
            return {
              label: project.value,
              key: `project_${tenant.id}_${project.id}`
            };
          })
        };
      })
  ];

  return (
    <div style={{ margin: "0px 15px", cursor: "pointer" }}>
      <Dropdown
        overlayClassName="project-dropdown"
        style={{ minWidth: 0, flex: "auto" }}
        trigger={"click"}
        onOpenChange={() => setVisibleProjectMenu(!visibleProjectMenu)}
        placement="bottomLeft"
        menu={{
          items,
          onClick: e => {
            if (e.key === "header_label" && localStorage.getItem(`isSuperUser`) === "true") {
              window.location.href = `/utils/tenants`;
            } else {
              if (!e.key.includes("project_")) return;
              var splitKey = e.key.split("_");
              var tenant = UserStore.projects.find(x => x.id === splitKey[1]);
              var project = tenant.projects.find(x => x.id === splitKey[2]);

              UserStore.switchProject(tenant, project).then(async () => {
                localStorage.removeItem(`projectId`);
                localStorage.removeItem(`tenantId`);
                localStorage.removeItem(`projectName`);
                history.push(`/`);
              });
            }
          }
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: 200,
            height: 33,
            backgroundColor: currentTheme === "light" ? "#f5f5f5" : "#141414",
            borderRadius: 5,
            margin: "6px -30px 0px -5px",
            overflow: "hidden"
          }}
        >
          <p style={{ padding: 0, fontSize: 10, margin: "-12px 0px 0px 5px " }}>{t("userInfo.project")}: </p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <p style={{ marginLeft: 5, padding: 0, marginTop: -30, fontWeight: 500, fontSize: 13 }}>
              {" "}
              {localStorage.getItem("projectName")}
            </p>
            <span style={{ marginTop: -38, marginRight: 5, fontSize: 11 }}>
              {visibleProjectMenu ? <CaretUpOutlined /> : <CaretDownOutlined />}{" "}
            </span>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(observer(ProjectsMenu));
