import { gql } from "@apollo/client";
import * as _ from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import BackendService from "../BackendService";
import GraphQlService from "../GraphQlService";
import { logout } from "./Logout";
import NotificationComponentStore from "../../Common/Notifications/NotificationComponentStore";
import ConfigStore from "../ConfigStore";

class UserStore {
  graphQlService = new GraphQlService();
  backendService = new BackendService(``);
  securityAccess = [];
  isLoading = true;

  projects = undefined;
  user = undefined;
  languages = [
    {
      code: "en",
      name: "English"
    },
    {
      code: "es",
      name: "Español"
    },
    {
      code: "pl",
      name: "Polski"
    }
  ];

  constructor() {
    makeAutoObservable(this);
  }

  loadUserAccess = () => {
    return new Promise((resolve, reject) => {
      this.graphQlService
        .client()
        .query({
          query: gql`
            {
              currentUser {
                id
                username
                email
                version
                projectId
                tenantId
                securityAccess
              }
            }
          `
        })
        .then(
          r => {
            var user = r.data.currentUser;
            if (!user) return resolve();

            localStorage.setItem(`username`, user.username);
            localStorage.setItem(`email`, user.email);
            localStorage.setItem(`isSuperUser`, user.version === -1 ? true : false);
            localStorage.setItem(`tenantId`, user.tenantId);
            localStorage.setItem(`projectId`, user.projectId);
            this.user = user;

            if (user.securityAccess) this.securityAccess = JSON.parse(user.securityAccess);

            return resolve(this.secu);
          },
          e => {
            if (e && e.message && e.message.includes("You are not authorized to run this query.")) return logout();
          }
        )
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  };

  hasAccess = path => {
    var isSuperUser = JSON.parse(localStorage.getItem(`isSuperUser`));

    if (isSuperUser) return true;

    if (!this.securityAccess || _.isUndefined(path)) return false;
    return (
      _.filter(this.securityAccess, x => {
        return x && x.toLowerCase() === path.toLowerCase();
      }).length > 0
    );
  };

  loadProjects = () => {
    NotificationComponentStore.updateNotifications();

    if (localStorage.getItem(`plan`) === "bas" && localStorage.getItem(`isSuperUser`) === false) return;

    this.graphQlService
      .get(`{ userTenants(email: "${localStorage.getItem(`email`)}") { id value projects { id value } } }`)
      .then(r => {
        const entry = r.data.userTenants;

        if (this.user.version == -1) return (this.projects = entry);

        const promises = [];

        _.forEach(entry, tenant => {
          _.forEach(tenant.projects, project => {
            promises.push(
              new Promise(resolve => {
                resolve(
                  this.graphQlService.get(`{project (id: "${project.id}") {payload}}`).then(v => {
                    const data = JSON.parse(v.data.project.payload);
                    let usersPermissions = [];

                    if (data.hasOwnProperty("UserPermissions")) {
                      usersPermissions = data["UserPermissions"];

                      while (typeof usersPermissions === "string") {
                        usersPermissions = JSON.parse(usersPermissions);
                      }
                    }

                    return { projectId: project.id, data: usersPermissions, tenantId: tenant.id };
                  })
                );
              })
            );
          });
        });

        Promise.all(promises).then(data => {
          const allowedProjects = _.map(data, ({ data, projectId, tenantId }) => {
            if (
              localStorage.getItem("projectId") === projectId &&
              !_.includes(data, this.user.id) &&
              data.length > 0 &&
              this.user.version > 0
            )
              logout();

            if (
              (_.includes(data, this.user.id) || data.length === 0) &&
              tenantId === localStorage.getItem("tenantId")
            ) {
              return projectId;
            }
          });
          _.forEach(entry, v => {
            v.projects = _.filter(v.projects, project => _.includes(allowedProjects, project.id));
          });

          this.projects = _.filter(entry, v => v.projects?.length > 0);
        });
      });
  };

  switchProject = (tenant, project) => {
    return this.graphQlService
      .post(
        `mutation switch($tenantId: Guid, $projectId: Guid) { projectSwitch(tenantId: $tenantId, projectId: $projectId) }`,
        { tenantId: tenant.id, projectId: project.id }
      )
      .then(async r => {
        var json = JSON.parse(r.data.projectSwitch);
        localStorage.setItem(`jwtToken`, json.jwt);

        var tenant = await this.graphQlService.get(`{ tenant { rootId plan headerLogo }}`);

        localStorage.setItem(`tenantId`, tenant.data.tenant.rootId);
        localStorage.setItem(`plan`, tenant.data.tenant.plan);

        let appConfig = JSON.parse(localStorage.getItem(`appConfig`));
        appConfig.header.path = ConfigStore.backendUrlHost + "/" + tenant.headerLogo;
        localStorage.setItem(`appConfig`, JSON.stringify(appConfig));

        return true;
      });
  };
}

export default new UserStore();
